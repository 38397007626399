import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Layout, SEO } from '@global'
import { Hero, Testimonials } from '@layouts'
import Phone from '@images/contact/icons/phone.svg'
import Clock from '@images/contact/icons/clock.svg'
import Mail from '@images/contact/icons/mail.svg'
import { WriteAReviewCallout } from '@layouts'
import Location from '@images/contact/icons/location.svg'
import { testimonials as testimonialsData } from '../../data/testimonials'

const ContactPage = () => {
  const data = useStaticQuery(graphql`
    query {
      hero: file(relativePath: { eq: "contact/Hero_Contact.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const heroData = data.hero.childImageSharp.fluid
  const testimonials = testimonialsData.slice(12, 15)
  return (
    <div className="relative">
      <SEO title="Contact" />
      <Layout>
        <Hero
          fluid={heroData}
          eyebrow="contact us"
          title="We can’t wait to hear from you"
        />
        <section className="py-10 lg:py-16 lg:pb-24">
          <div className="container">
            <div className="max-w-lg mx-auto lg:mx-0 lg:max-w-sm">
              <h2 className="text-4xl font-bold text-blue-400 lg:text-5xl">
                Contact
              </h2>
              <p className="mt-4 text-sm text-gray-400 lg:text-lg">
                Fill out the form below and we’ll be in touch shortly or feel
                free to contact us directly.
              </p>
            </div>
            <div className="mt-10 lg:grid lg:grid-cols-12 lg:gap-5">
              <div className="max-w-lg mx-auto lg:col-span-5 lg:mx-0 lg:max-w-md">
                <div className="">
                  <form
                    name="Contact"
                    method="post"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                  >
                    <input type="hidden" name="bot-field" />
                    <input type="hidden" name="form-name" value="Contact" />
                    <label className="block">
                      <span className="text-2xl font-bold text-blue-300 capitalize">
                        Name
                      </span>
                      <input
                        className="block w-full p-3 px-4 mt-2 placeholder-gray-400 bg-transparent border-2 border-gray-400 form-input"
                        placeholder="John Doe"
                        type="text"
                        name="name"
                        required
                      />
                    </label>
                    <label className="block mt-4">
                      <span className="text-2xl font-bold text-blue-300 capitalize">
                        Phone
                      </span>
                      <input
                        type="tel"
                        className="block w-full p-3 px-4 mt-2 placeholder-gray-400 bg-transparent border-2 border-gray-400 form-input"
                        placeholder="(555) 555-5555"
                        name="phone"
                        required
                      />
                    </label>
                    <label className="block mt-4">
                      <span className="text-2xl font-bold text-blue-300 capitalize">
                        Email
                      </span>
                      <input
                        className="block w-full p-3 px-4 mt-2 placeholder-gray-400 bg-transparent border-2 border-gray-400 form-input"
                        placeholder="John@email.com"
                        type="email"
                        name="email"
                        required
                      />
                    </label>
                    <label className="block mt-4">
                      <span className="text-2xl font-bold text-blue-300 capitalize">
                        Message
                      </span>
                      <textarea
                        rows="5"
                        className="block w-full p-3 px-4 mt-2 placeholder-gray-400 bg-transparent border-2 border-gray-400 form-textarea"
                        placeholder="I have a question about..."
                        name="message"
                      />
                    </label>
                    <button
                      type="submit"
                      className="w-full mt-6 text-lg btn sm:text-xl"
                    >
                      submit
                    </button>
                  </form>
                </div>
              </div>
              <div className="py-10 lg:col-start-7 lg:col-span-6 lg:py-0 lg:mt-2">
                <div className="mt-8 text-xl text-blue-400 lg:mt-0">
                  <div className="flex flex-col items-center lg:flex-row">
                    <div className="flex items-center justify-center w-16">
                      <img src={Phone} alt="Phone icon" />
                    </div>
                    <div className="mt-4 text-center lg:mt-0 lg:text-left lg:ml-5">
                      <a href="tel:3177860360">(317) 786-0360</a>
                    </div>
                  </div>
                  <div className="flex flex-col items-center mt-10 lg:flex-row">
                    <div className="flex items-center justify-center w-16">
                      <img src={Mail} alt="Mail icon" />
                    </div>
                    <div className="mt-4 text-center lg:mt-0 lg:text-left lg:ml-5">
                      <a href="mailto:updikebathroom@comcast.net">
                        updikebathroom@comcast.net
                      </a>
                    </div>
                  </div>
                  <div className="flex flex-col items-center mt-10 lg:flex-row">
                    <div className="flex items-center justify-center w-16">
                      <img src={Clock} alt="Clock icon" />
                    </div>
                    <div className="mt-4 text-center lg:mt-0 lg:text-left lg:ml-5">
                      <div>
                        <span className="mr-6 font-bold lg:mr-8">
                          Monday-Thursday
                        </span>
                        <span>8AM-4PM</span>
                      </div>
                      <div>
                        <span className="mr-6 font-bold lg:mr-8">
                          Friday
                        </span>
                        <span>8AM-3PM</span>
                      </div>
                      <div>
                        <span className="mr-6 font-bold lg:mr-8">Saturday</span>
                        <span>By appointment only</span>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col items-center mt-10 lg:flex-row">
                    <div className="flex items-center justify-center w-16">
                      <img src={Location} alt="Location icon" />
                    </div>
                    <div className="mt-4 text-center lg:mt-0 lg:text-left lg:ml-5">
                      7494 Madison Ave <br /> Indianapolis, IN 46227
                    </div>
                  </div>
                  <div className="flex flex-col items-center mt-10 lg:flex-row">
                    <div className="max-w-sm mt-4 text-base text-center lg:mt-0 lg:text-left lg:ml-5">
                      *Please note that we only accept payment via cash or check
                      at this time.
                    </div>
                  </div>
                  <div className="h-64 max-w-lg mx-auto mt-10 lg:mx-0 lg:max-w-md">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3071.635145744665!2d-86.12735948437893!3d39.65792500936012!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x886b5c8fd983d943%3A0x53a4a75d1d26ea22!2sUpdike%20Bathroom%20Remodeling%20Co!5e0!3m2!1sen!2sus!4v1582764694747!5m2!1sen!2sus"
                      frameBorder="0"
                      style={{ border: `0` }}
                      allowFullScreen=""
                      height="100%"
                      width="100%"
                      title="Map of location"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <WriteAReviewCallout />
        <Testimonials data={testimonials} />
      </Layout>
    </div>
  )
}
export default ContactPage
